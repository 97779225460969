import { OrgType } from 'common-ui';

export interface GenesysRegionOption {
  name: string;
  targetEnv: string;
  hostOrigin: string;
}

export const GENESYS_REGIONS: GenesysRegionOption[] = [
  {
    name: 'Americas (Canada)',
    targetEnv: 'prod-cac1',
    hostOrigin: 'https://cac1.pure.cloud',
  },
  {
    name: 'Americas (Sāo Paulo)',
    targetEnv: 'prod-sae1',
    hostOrigin: 'https://sae1.pure.cloud',
  },
  {
    name: 'Americas (US East)',
    targetEnv: 'prod',
    hostOrigin: 'https://mypurecloud.com',
  },
  {
    name: 'Americas (US West)',
    targetEnv: 'prod-usw2',
    hostOrigin: 'https://usw2.pure.cloud',
  },
  {
    name: 'Asia Pacific (Mumbai)',
    targetEnv: 'prod-aps1',
    hostOrigin: 'https://aps1.pure.cloud',
  },
  {
    name: 'Asia Pacific (Osaka)',
    targetEnv: 'prod-apne3',
    hostOrigin: 'https://apne3.pure.cloud',
  },
  {
    name: 'Asia Pacific (Seoul)',
    targetEnv: 'prod-apne2',
    hostOrigin: 'https://apne2.pure.cloud',
  },
  {
    name: 'Asia Pacific (Sydney)',
    targetEnv: 'prod-apse2',
    hostOrigin: 'https://mypurecloud.com.au',
  },
  {
    name: 'Asia Pacific (Tokyo)',
    targetEnv: 'prod-apne1',
    hostOrigin: 'https://mypurecloud.jp',
  },
  {
    name: 'EMEA (Dublin)',
    targetEnv: 'prod-euw1',
    hostOrigin: 'https://mypurecloud.ie',
  },
  {
    name: 'EMEA (Frankfurt)',
    targetEnv: 'prod-euc1',
    hostOrigin: 'https://mypurecloud.de',
  },
  {
    name: 'EMEA (London)',
    targetEnv: 'prod-euw2',
    hostOrigin: 'https://euw2.pure.cloud',
  },
  {
    name: 'EMEA (UAE)',
    targetEnv: 'prod-mec1',
    hostOrigin: 'https://mec1.pure.cloud',
  },
  {
    name: 'EMEA (Zurich)',
    targetEnv: 'prod-euc2',
    hostOrigin: 'https://euc2.pure.cloud',
  },
  {
    name: 'FedRAMP',
    targetEnv: 'fedramp-use2-core',
    hostOrigin: 'use2.us-gov-pure.cloud',
  },
];

const GENESYS_URL = process.env.REACT_APP_GENESYS_URL ?? `https://genesys.${window.location.host}`;
export const AUTH_URL = {
  [OrgType.Cinareo]: `${window.location.protocol}//${window.location.host}/api/hubspot/jwt${window.location.search}`,
  [OrgType.Genesys]: `${GENESYS_URL}/hubspot${window.location.search}`,
};
